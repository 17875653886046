import React from 'react';
import HomeContestSection from '../components/homeContestSection';
import { MdArrowBack } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';

export default function ContestListScreen() {
    const location = useLocation()
    const navigate = useNavigate()


    return (
        <div className='main_warp' >
            <div style={{ height: 48, width: "100%", boxShadow: "0 4px 4px rgba(0,0,0,0.1)", marginBottom: 1, display: "flex", alignItems: "center", padding: "0 16px", position: "sticky", top: 0 }}>
                <MdArrowBack size={24} onClick={() => { navigate('/home') }} cursor="pointer" />
                <h4 style={{ fontFamily: "Open Sans", fontSize: 20, marginLeft: 10, lineHeight: "1px", marginTop: 5 }}>#TakeTheStep</h4>

                <button
                    data-bs-toggle="modal"
                    data-bs-target="#languageModal"
                    type="button"
                    className="btn btn-secondary-light search-hide" style={{ padding: "7px 10px", marginLeft: "auto", marginRight: 10 }}>
                    {/* <i className="fas fa-pin-location fa-lg me-1" style={{ color: '#605e5c', textTransform: "capitalize",marginRight:5 }}></i> */}
                    <div className="bd-highlight col-1 px-0 align-self-center pe-1">
                        <div className="tabs-btn" data-bs-toggle="modal"
                            data-bs-target="#languageModal" >
                            <img alt='localbol' src="assets/images/NavIcons.svg" />
                        </div>
                    </div>
                </button>
            </div>
            <HomeContestSection redirectToMyChallenge={location?.state?.redirectToMyChallenge ? true : false} />
        </div>
    )
}