import axios from "axios";
import { SUBDOMAIN_ID } from "../constants/subdomain";
import {
    rootlink
} from "./link";

async function customRequest(path, body = {}, method = 'POST') {
    const token = localStorage.getItem('token');
    const headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'subdomain': SUBDOMAIN_ID
    };
    if (token) {
        headers.authorization = `Bearer ${token}`;
    }

    const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => {
            reject(new Error('Request timed out please try again'));
        }, 12000);
    });
    try {
        const response = await Promise.race([
            fetch(`${rootlink}/${path}`, {
                method: method,
                body: JSON.stringify({ ...body }),
                headers: headers,
            }),
            timeoutPromise, // the timeout promise to the race
        ]);
        // console.log(response)

        // if (!response.ok) {
        //     return await response.json()
        // }

        return await response.json();
    } catch (error) {
        throw new Error(error.message);
    }
}


function customRequestGET(path) {
    const token = localStorage.getItem('token');

    return fetch(`${rootlink}/${path}`, {
        method: "GET",
        headers: {
            'Content-Type': "application/json",
            'accept': 'application/json',
            'authorization': "Bearer " + token,
            'subdomain': SUBDOMAIN_ID

        }
    })
        .then(res => {
            return res.json()
        })
        .then((json) => {
            return json
        });
}

function customRequestGETBare(path) {
    const token = localStorage.getItem('token');

    return fetch(path, {
        method: "GET",

        // headers: {
        //     'Content-Type': "application/json",
        //     'accept': 'application/json',
        // }
    })
        .then(res => {
            return res.json()
        })
        .then((json) => {
            return json
        });
}


function customMultipartRequest(path, body = {}, method = 'POST') {
    const token = localStorage.getItem('token');

    return fetch(`${rootlink}/${path}`, {
        method: method,
        body: body,
        headers: {
            // 'Content-Type': "multipart/form-data",
            'accept': 'application/json',
            'authorization': "Bearer " + token,
            'subdomain': SUBDOMAIN_ID

        }
    })
        .then(res => {
            return res.json()
        })
        .then((json) => {
            return json
        });
}




// Helper function for handling errors
function handleErrors(error) {
    if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        console.error('Response Error:', error.response.data);
        throw error.response.data;
    } else if (error.request) {
        // The request was made but no response was received
        console.error('Request Error:', error.request);
        throw { message: 'No response received from the server.' };
    } else {
        // Something happened in setting up the request that triggered an error
        console.error('Error:', error.message);
        throw { message: 'An error occurred while making the request.' };
    }
}

// Default headers
// const defaultHeaders = {
//     'Content-Type': 'application/json',
//   };

// Function for making GET requests
export async function getApiService(path, data = {}, options = {}) {
    const { timeout } = options;
    const token = localStorage.getItem('token');

    try {
        const response = await axios.request({
            method: 'get',
            url: `${rootlink}/${path}`,
            data: data,  // Include data property for the request body
            headers: {
                'Content-Type': 'application/json',  // Set the appropriate content type
                'subdomain': SUBDOMAIN_ID,
                Authorization: `Bearer ${token}`
            },
            timeout: timeout || 5000,  // Default timeout is 5 seconds
        });

        return response.data;
    } catch (error) {
        console.log(error.request);
        handleErrors(error);
    }
}

// Function for making POST requests
export async function postApiService(path, body = {}, options = {}) {
    const { debounce, headers, contentType } = options;
    const token = localStorage.getItem('token');

    const config = {
        //  timeout: timeout || 5000, // Default timeout is 5 seconds
        headers: {
            ...headers,
            'subdomain': SUBDOMAIN_ID,
            'Content-Type': contentType || 'application/json',
            Authorization: `Bearer ${token}`
        },
    };

    if (debounce) {
        // Implement  debounce 

    }

    try {
        const response = await axios.post(`${rootlink}/${path}`, body, config);
        return response.data;
    } catch (error) {
        handleErrors(error);
    }
}
export {
    customRequest,
    customMultipartRequest,
    customRequestGET,
    customRequestGETBare
}