import React from 'react'
import Moment from 'react-moment'
import AcceptChallengeWithDateOption from '../../../components/acceptChallengeWithDateOption'
import CustomButton from '../../../components/CustomButton'

const ChallengeDetails = ({ showCalender, setShowCalender, contest, isParticipated, handleParticipate, participateDate, setParticipation_date }) => {

    return (
        <div>
            <div class="d-flex flex-column bd-highlight mb-4 p-3 pb-4">
                <div class="py-1 bd-highlight">
                    <button type="button" class="btn btn-warning-light">#{contest?.hashtag?.split(" ")[0]}</button>
                </div>
                {
                    contest?.details?.map((detail, index) => {
                        return (
                            <div class="py-1 bd-highlight" key={index}>
                                <h4>{detail?.title}</h4>
                                <p dangerouslySetInnerHTML={{ __html: detail?.description }}></p>
                            </div>
                        )
                    })
                }
                <div class="py-1 bd-highlight" style={{ marginBottom: 40 }}>
                    <span style={{ display: "block", fontWeight: "600" }}>Note : </span>
                    {/* <span style={{ display: "block", fontSize: 14 }}>Start Date: <Moment format='hh:mm A DD/MM/YYYY' date={contest.start_date} /></span> */}
                    <span style={{ display: "block", fontSize: 14 }}>Last Entry Date:  <Moment format='DD/MM/YYYY' date={contest?.end_date} /></span>
                    {/* <span style={{ display: "block", fontSize: 14 }}>Result Date:  <Moment format='hh:mm A DD/MM/YYYY' date={contest.result_date} /></span> */}

                </div>
                {!isParticipated && <div className="productdetailfooter">
                    <CustomButton className={"btn-block"} onClick={() => setShowCalender(true)} label={"Accept challenge"} />
                </div>}
            </div>
            {showCalender && <AcceptChallengeWithDateOption setShowCalender={setShowCalender} handleParticipate={handleParticipate} setParticipation_date={setParticipation_date} participateDate={participateDate} startDate={contest?.start_date} endDate={contest?.end_date} />
            }
        </div>
    )
}

export default ChallengeDetails