import React from 'react'
import FcLogo from '../assets/main_logo.png'

const Brand = ({ customStyle }) => {
    return (
        <img src={FcLogo} alt="SVG Image" style={{ height: "35vh", width: "100%", zIndex: 2, objectFit: "contain",mixBlendMode:"multiply",...customStyle}} />

    )
}

export default Brand