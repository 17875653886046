import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AllPostsScreen from "../screens/AllPosts";
import AuthoritiesScreen from "../screens/Authorities";
import ContestScreen from "../screens/challenges";
import CreatorProfileScreen from "../screens/CreatorProfile";
import HomeScreen from "../screens/home";
import LoginScreen from "../screens/auth/Login";
import LoginWithEmail from "../screens/auth/LoginWithEmail";
import FaqScreen from "../screens/Faq";
import InfoScreen from "../screens/Info";
import RegisterScreen from "../screens/auth/Register";
import OtpScreen from "../screens/auth/otp";
import MyProfileScreen from "../screens/myProfile";
import NewPostScreen from "../screens/newPost";
import RegisterPhoneScreen from "../screens/auth/registerPhone";
import RegisterVerifyOtpScreen from "../screens/auth/registerVerifyOtp";
import SelectLocationScreen from "../screens/selectLocation";
import LanguageSelectionScreen from "../screens/auth/languageSelection";
import LocationSelectionScreen from "../screens/auth/locationSelection";
import ContestPostScreen from "../screens/contestPost";
import SinglePostScreen from "../screens/singlePost";
import EditPostScreen from "../screens/editPost";
import EditProfileScreen from "../screens/editProfile";
import LocationSearchScreen from "../screens/locationSearch";
import RespectorAndRespectingScreen from "../screens/respectorAndRespecting";
import AlertPopup from "../components/alertPopup";
import ContestListScreen from "../screens/contestList";
import ChallengeContent from "../screens/ChallengeContent";
import DynamicAlert from "../components/DynamicAlert";
import { useSelector } from "react-redux";
import CreateAccount from "../screens/auth/CreateAccount/CreateAccount";
import ForgotPassword from "../screens/auth/ForgotPassword";
import ChangePassword from "../screens/auth/ChangePassword";
import TermsAndCondition from "../screens/TermsAndCondition";
import Privacy from "../screens/Privacy";
import ShareContest from "../screens/ShareContest";
import NotFound from "../screens/NotFound";
import ConfirmationPage from "../screens/payment-confirmation";

export default function NavigationManager() {
  const { alert } = useSelector(state => state.alert)

  return (
    <BrowserRouter>
      <AlertPopup />
      {alert?.show && <DynamicAlert />}
      <Routes>
        <Route path="/" element={<LoginScreen />} />
        <Route path="/login" element={<LoginWithEmail />} />

        <Route path="/register" element={<RegisterScreen />} />
        <Route path="/signup-with-google" element={<RegisterPhoneScreen />} />
        <Route path="/registerverifyotp" element={<RegisterVerifyOtpScreen />} />
        <Route path="/selectlocation" element={<SelectLocationScreen />} />
        <Route path="/languageSelectionScreen" element={<LanguageSelectionScreen />} />
        <Route path="/locationSelectionScreen" element={<LocationSelectionScreen />} />
        <Route path="/locationSearchScreen" element={<LocationSearchScreen />} />
  {/* <Route path="/create-account" element={<RegisterPhoneScreen />} /> */}
        <Route path="/create-account" element={<CreateAccount />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/change-password" element={<ChangePassword />} />

        <Route path="/verifyotp" element={<OtpScreen />} />

        <Route path="/home" element={<HomeScreen />} />
        <Route path="/posts" element={<AllPostsScreen />} />
        <Route path="/post/:postid" element={<SinglePostScreen />} />
        <Route path="/editpost/:postid" element={<EditPostScreen />} />

        <Route path="/newpost" element={<NewPostScreen />} />
        <Route path="/contestpost" element={<ContestPostScreen />} />

        <Route path="/authorities" element={<AuthoritiesScreen />} />
        <Route path="/contest" element={<ContestScreen />} />
        <Route path="/contestlist" element={<ContestListScreen />} />
        <Route path="/contest/:contestId" element={<ShareContest />} />
        <Route path="/myprofile" element={<MyProfileScreen />} />
        <Route path="/userprofile" element={<CreatorProfileScreen />} />
        <Route path="/editprofile" element={<EditProfileScreen />} />
        <Route path="/respect/:type" element={<RespectorAndRespectingScreen />} />
        <Route path="/payment-confirmation" element={<ConfirmationPage />} />
        <Route path="/faq" element={<FaqScreen />} />
        <Route path="/info" element={<InfoScreen />} />
        <Route path="/challenge-content" element={<ChallengeContent />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/terms-and-condition" element={<TermsAndCondition />} />
          {/* The catch-all route for 404 errors */}
          <Route path="*" element={<NotFound/>} />
      </Routes>
    </BrowserRouter>
  );
}
