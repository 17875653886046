import React from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Brand from "../../components/Brand";
import CustomButton from "../../components/CustomButton";
import CustomInput from "../../components/CustomInput";
import { Colors } from "../../constants/colors";
import { SUBDOMAIN_NAME } from "../../constants/subdomain";
import { customRequest } from "../../functions/request";
import { setAlert } from "../../redux/alertSlice";
import { setShowAlertPopup } from "../../redux/dataSlice";

export default function RegisterScreen() {
  let location = useLocation();
  let [fullname, setFullname] = React.useState(location?.state?.full_name ? location?.state?.full_name : "");
  let [username, setUsername] = React.useState("");
  let [referralCode, setReferralCode] = React.useState("");
  let navigate = useNavigate();
  let [usernameCheck, setUsernameCheck] = React.useState(null);
  const dispatch = useDispatch()

  const handleInput = (e) => {
    if (e.target.name == "full_name") {
      setFullname(e.target.value);
    } else if (e.target.name == "username") {
      setUsername(e.target.value);
    } else if (e.target.name == "referral_code") {
      setReferralCode(e.target.value);
    }
  };


  function showAlertPopup(message) {
    dispatch(
      setShowAlertPopup({
        show: true,
        title: 'Alert',
        message,
      })
    );
  }

  const handleRegister = () => {

    if (!location.state?.email || !location.state?.email_verified || !location.state?.picture || !location.state?.google_id) {
      showAlertPopup('Something went wrong')
      return;
    }
    if (!fullname) {
      showAlertPopup("Please enter fullname")
      return;

    }
    if (!username) {
      showAlertPopup("Please enter username")
      return;

    }


    let data = {
      email: location.state?.email,
      full_name: fullname,
      full_name: fullname,
      username: username,
      referral_code: referralCode,
      email_verified: location.state?.email_verified,
      picture: location.state?.picture,
      google_id: location.state?.google_id,
    };

    localStorage.setItem('register_user_data', JSON.stringify(data));
    navigate('/languageSelectionScreen');
    // customRequest('user/checkusername', { username: username }).then((res) => {
    //   //console.log(res);
    //   if (res.status === "fail") {
    //     setUsernameCheck(res.message);
    //   } else {
    //     let data = {
    //     //  phone: location.state.phone,
    //       full_name: fullname,
    //       username: username,
    //       referral_code: referralCode,
    //       email_verified:location.state?.email_verified,
    //       picture:location.state?.picture,
    //       google_id:location.state?.google_id,
    //     };

    //     localStorage.setItem('register_user_data', JSON.stringify(data));
    //     navigate('/languageSelectionScreen');
    //   }
    // })
  };



  return (
    <div className="mb-4 pt-4" style={{
      width: "100vw",
      display: "flex",
      flexDirection: "column",
      paddingTop: 10,
      height: '100vh',
      overflow: 'scroll'
    }}>
      {/* <img src={require("../../assets/login-back.png")} style={{ position: "absolute", zIndex: 1, mixBlendMode: "soft-light" }} /> */}
      {/* <div className='p-4 m-4'> */}
      <img className="mt-4" src={require("../../assets/logo.png")} style={{ height: "25vh", width: "80%", zIndex: 2, objectFit: "contain",alignSelf:"center" }} />
      {/* </div> */}
      <div style={{ backgroundColor: Colors.white, boxShadow: "0 -4px 10px rgba(0,0,0,0.1)", paddingTop: "18px", borderTopRightRadius: 20, borderTopLeftRadius: 20, marginTop: "auto", zIndex: 2 }}>
      <div className="py-4 px-3" style={{ display: 'flex', flexDirection: 'column', maxWidth: '600px', margin: '0 auto' }}>
          <h4 style={{ fontSize: 19, fontWeight: "700", lineHeight: 1 }}><span style={{ color: Colors.primary }}>Setup</span> Your Account</h4>
          <span style={{ fontSize: 12, fontFamily: "Open Sans", color: "gray" }}>Create your username to help your friends find you on the {SUBDOMAIN_NAME}</span>

          <CustomInput className={"mt-2"} type={"text"} name="full_name" value={fullname} onChange={handleInput} placeholder={"Full Name*"} />
          <CustomInput className={"mt-2"}  value={username}
            type="text"
            name="username"
            placeholder="Username*" onChange={handleInput} />
          <CustomInput className={"mt-2"}  value={referralCode}
            type="text"
            name="referral_code"
            placeholder="Referral Code (optional)" onChange={handleInput} />

          {/* <div>
            <label htmlFor="phone" style={{ fontSize: 12, color: "gray", marginTop: 16, fontFamily: "Open Sans" }}>First Name</label>
            <input
              value={fullname}
              type="text"
              name="full_name"
              placeholder="Full Name"
              style={{ display: "block", borderRadius: 8, backgroundColor: "rgba(0,0,0,0.1)", border: "none", fontSize: 14, padding: "7px 8px", fontFamily: "Open Sans", width: "100%" }}
              onChange={handleInput}
            />
          </div>
          <div>
            <label htmlFor="username" style={{ fontSize: 12, color: "gray", fontFamily: "Open Sans" }}>Username</label>
            <input
              value={username}
              type="text"
              name="username"
              placeholder="Username"
              style={{ display: "block", borderRadius: 8, backgroundColor: "rgba(0,0,0,0.1)", border: "none", fontSize: 14, padding: "7px 8px", fontFamily: "Open Sans", width: "100%" }}
              onChange={handleInput}
            />
          </div>
          {
            usernameCheck === null
              ?
              null
              :
              <span style={{ fontFamily: "Open Sans", fontSize: 12, color: "red" }}>{usernameCheck}</span>
          }
          <div>
            <label htmlFor="phone" style={{ fontSize: 12, color: "gray", fontFamily: "Open Sans" }}>Referral Code(optional)</label>
            <input
              value={referralCode}
              type="text"
              name="referral_code"
              placeholder="Referral Code"
              style={{ display: "block", borderRadius: 8, backgroundColor: "rgba(0,0,0,0.1)", border: "none", fontSize: 14, padding: "7px 8px", fontFamily: "Open Sans", width: "100%" }}
              onChange={handleInput}
            />
          </div> */}

          <CustomButton className={"btn-block mt-4"} label={"Continue"} onClick={ handleRegister}/>
          {/* <button onClick={() => { handleRegister() }} style={{ marginTop: 12, borderRadius: 60, backgroundColor: "#6945B4", border: "none", width: "100%", fontFamily: "Open Sans", color: "white", padding: "7px" }}>
            Continue
          </button> */}

        </div>
      </div>
    </div>
  );
}
