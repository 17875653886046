import React, { useRef } from "react";
import { Colors } from "../../constants/colors";

const WhatsAppButton = ({ link, text }) => {
  const buttonRef = useRef(null);

  return (
    <div className="my-3 container">
      <a
        ref={buttonRef}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="d-flex align-items-center justify-content-center"
        style={{
          backgroundColor: Colors.green,
          color: "#fff",
          textDecoration: "none",
          borderRadius: "8px",
          padding: "12px 16px",
          transition: "transform 0.2s, box-shadow 0.2s",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          display: "inline-block"
        }}
        onMouseEnter={() => {
          if (buttonRef.current) {
            buttonRef.current.style.transform = "scale(1.05)";
            buttonRef.current.style.boxShadow = "0 6px 10px rgba(0, 0, 0, 0.15)";
          }
        }}
        onMouseLeave={() => {
          if (buttonRef.current) {
            buttonRef.current.style.transform = "scale(1)";
            buttonRef.current.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.1)";
          }
        }}
      >
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
          alt="WhatsApp"
          style={{
            width: 24,
            height: 24,
            marginRight: 8,
          }}
        />
        <span style={{ fontSize: "16px", fontWeight: "bold" }}>{text}</span>
      </a>
    </div>
  );
};

export default WhatsAppButton;
