import React from "react";
import logo from "../assets/main_logo.png";

const TermsAndCondition = () => {
    return (
        <div
            className="container-fluid bg-light"
            style={{
                width: "100vw",
                display: "flex",
                flexDirection: "column",
                paddingTop: 10,
                paddingBottom: 20,
            }}
        >
            {/* <Brand/> */}
            <img
                src={logo}
                style={{
                    height: "30vh",
                    width: "100%",
                    zIndex: 2,
                    objectFit: "contain",
                    mixBlendMode:"multiply"
                }}
            ></img>
            <div className="container text-justify mt-2">
                <h3 className=" font-weight-bold">Terms and Conditions</h3>
                <p className="text-justify ">Welcome to Fittest Club!</p>
            </div>
            <p className="text container">
                These terms and conditions outline the rules and regulations for the use of Orange Cup Marketing and Communications Private Limited's Website, located at New Delhi, India.
            </p>

            <p className="text-justify container ">
                By accessing this website, we assume you accept these terms and conditions. Do not continue to use Fittest Club if you do not agree to take all of the terms and conditions stated on this page.
            </p>
            <div className="container text-justify text-center ">
                <h3 className="mb-2">Cookies</h3>
                <p className="text-justify">
                    The website uses cookies to help personalize your online experience. By accessing Fittest Club, you agreed to use the required cookies.
                </p>
            </div>
            <p className="text-justify container ">
                A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you and can only be read by a web server in the domain that issued the cookie to you.
            </p>
            <p className="text-justify container ">
                We may use cookies to collect, store, and track information for statistical or marketing purposes to operate our website. You have the ability to accept or decline optional Cookies. There are some required Cookies that are necessary for the operation of our website. These cookies do not require your consent as they always work. Please keep in mind that by accepting required Cookies, you also accept third-party Cookies, which might be used via third-party provided services if you use such services on our website, for example, a video display window provided by third parties and integrated into our website.
            </p>
            <div className="container text-justify text-center">
                <h3 className="mb-2">License</h3>
                <p className="text-justify  ">
                    Unless otherwise stated, Orange Cup Marketing and Communications Private Limited and/or its licensors own the intellectual property rights for all material on Fittest Club. All intellectual property rights are reserved. You may access this from Fittest Club for your own personal use subjected to restrictions set in these terms and conditions.
                </p>
            </div>
            <div className="container text-justify text-center">
                <h3 className="mb-2"> You must not</h3>
                <ul>
                    <li className="text-justify ">
                        Copy or republish material from Fittest Club
                    </li>
                    <li className="text-justify ">
                        Sell, rent, or sub-license material from Fittest Club
                    </li>
                    <li className="text-justify ">
                        Reproduce, duplicate or copy material from Fittest Club
                    </li>
                    <li className="text-justify ">
                        Redistribute content from Fittest Club
                    </li>
                    <li className="text-justify ">
                        This Agreement shall begin on the date hereof.
                    </li>
                    <li className="text-justify ">
                        Parts of this website offer users an opportunity to post and exchange opinions and information in certain areas of the website. Orange Cup Marketing and Communications Private Limited does not filter, edit, publish or review Comments before their presence on the website. Comments do not reflect the views and opinions of Orange Cup Marketing and Communications Private Limited, its agents, and/or affiliates. Comments reflect the views and opinions of the person who posts their views and opinions. To the extent permitted by applicable laws, Orange Cup Marketing and Communications Private Limited shall not be liable for the Comments or any liability, damages, or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.
                    </li>
                    <li className="text-justify ">
                        Orange Cup Marketing and Communications Private Limited reserves the right to monitor all Comments and remove any Comments that can be considered inappropriate, offensive, or causes breach of these Terms and Conditions
                    </li>
                </ul>
            </div>
            <div className="container text-justify text-center">
                <h3 className="mb-2"> You warrant and represent that</h3>{" "}
                <ul>
                    <li className="text-justify">
                        You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;
                    </li>

                    <li className="text-justify">
                        The Comments do not invade any intellectual property right, including without limitation copyright, patent, or trademark of any third party;
                    </li>

                    <li className="text-justify">
                        The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.

                    </li>
                    <li className="text-justify">
                        You hereby grant Orange Cup Marketing and Communications Private Limited a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats, or media.
                    </li>
                </ul>
            </div>
            <div className="container text-justify text-center">
                <h3 className="mb-2">
                    Hyperlinking to our Content
                </h3>
                <h6 className="mb-2">
                    The following organizations may link to our Website without prior
                    written approval
                </h6>

                <ul>
                    <li className="text-justify">Government agencies.</li>
                    <li className="text-justify">Search engines.</li>
                    <li className="text-justify">News organizations.</li>
                </ul>
            </div>
            <p className="text-justify  container">
                Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and
                System-wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.
            </p>
            <p className="text-justify container">
                These organizations may link to our home page, to publications, or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement, or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party's site.

            </p>
            <div className="container text-justify text-center">
                <h5 className="mb-2 ">
                We may consider and approve other link requests from the following types of organizations:

                </h5>
                <ul>
                    <li className="text-justify">
                    Commonly-known consumer and/or business information sources;
                    </li>
                    <li className="text-justify">Dot.com community sites;</li>
                    <li className="text-justify">
                    Associations or other groups representing charities;
                    </li>
                    <li className="text-justify">Online directory distributors;</li>
                    <li className="text-justify">Internet portals.</li>
                    <li className="text-justify">
                        Accounting, law, and consulting firms.
                    </li>
                    <li className="text-justify">
                        Educational institutions and trade associations.
                    </li>
                </ul>
            </div>
            <p className="text-justify container">
            We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of Orange Cup Marketing and Communications Private Limited; and (d) the link is in the context of general resource information.
            </p>
            <p className="text-justify container">
            These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement, or approval of the linking party and its products or services; and (c) fits within the context of the linking party's site.
            </p>
            <p className="text-justify container">
            If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to Orange Cup Marketing and Communications Private Limited. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.
            </p>
            <div className="container text-justify text-center">
                <h5 className="mb-2 ">
                    Approved organizations may hyperlink to our Website as follows
                </h5>
                <ul>

                    <li className="text-justify">By use of our corporate name or</li>
                    <li className="text-justify">
                        By use of the uniform resource locator being linked to or
                    </li>
                    <li className="text-justify">
                    Using any other description of our Website being linked to that makes sense within the context and format of content on the linking party's site.
                    </li>
                    <li className="text-justify">
                    No use of Orange Cup Marketing and Communications Private Limited's logo or other artwork will be allowed for linking absent a trademark license agreement.
                    </li>
                </ul>
            </div>
            <div className="container text-justify text-center">
                <h3 className="mb-2  ">
                    Content Liability
                </h3>
                <p className="text-justify ">
                We shall not be held responsible for any content that appears on your Website. You agree to protect and defend us against all claims that are raised on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene, or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.
                </p>
            </div>
            <div className="container text-justify text-center">
                <h3 className="mb-2">Reservation of Rights</h3>
                <p className="text-justify ">
                We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amend these terms and conditions and its linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions..
                </p>
            </div>
            <div className="container text-justify text-center">
                <h3 className="mb-2">Removal of links from our website</h3>
                <p className="text-justify ">
                If you find any link on our Website that is offensive for any reason, you are free to contact and inform us at any moment. We will consider requests to remove links, but we are not obligated to or so or to respond to you directly.
                </p>
            </div>
            <p className="text-justify container">
            We do not ensure that the information on this website is correct. We do not warrant its completeness or accuracy, nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.
            </p>
            <div className="container text-justify">
                <h3 className="mb-2 text-center">Disclaimer</h3>
                <p className="text-justify ">
                To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our website and the use of this website. Nothing in this disclaimer will:
                </p>
                <ul>
                    <li className="text-justify ">
                    Limit or exclude our or your liability for death or personal injury;
                    </li>
                    <li className="text-justify ">
                    Limit or exclude our or your liability for fraud or fraudulent misrepresentation;
                    </li>
                    <li className="text-justify ">
                    Limit any of our or your liabilities in any way that is not permitted under applicable law; or
                    </li>
                    <li className="text-justify ">
                    Exclude any of our or your liabilities that may not be excluded under applicable law.
                    </li>
                </ul>
            </div>

            <p className="text-justify container">
            The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort, and for breach of statutory duty.
            </p>
            <p className="text-justify container">
            As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.
            </p>
        </div>
    );
};

export default TermsAndCondition;
