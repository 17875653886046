import React from 'react';



const HorizontalContestTab = ({ selectedTab, handleSelectTab, tabsData, hideScheduleTab }) => {
 

  const handleTabClick = (tab) => {
    handleSelectTab(tab)
  };

  return (
    <div style={{ overflowX: 'scroll', whiteSpace: 'nowrap', backgroundColor: '#ffffff', }}>
      <div
        style={{
          display: 'flex',
          padding: '10px',
          transition: 'transform 0.3s ease-in-out',
        }}
      >
        {tabsData.map((tab, index) => (
          tab?.id === tabsData[1]?.id && hideScheduleTab ? null :
            <div
              key={index}
              onClick={() => handleTabClick(tab)}
              style={{
                textAlign: 'center',
                cursor: 'pointer',
                margin: '0 5px',
                padding: '5px',
                fontWeight:  selectedTab?.id === tab?.id ? 'bold' : 'normal',
              }}
            >
              {tab?.name}
              {
                selectedTab?.id === tab?.id
                  ?
                  <span className="sliderlineactive"></span>
                  :
                  null
              }
            </div>

        ))}
      </div>
    </div>
  );
};

export default HorizontalContestTab;
