import './App.css';
import NavigationManager from './routes/navigation';
import { Provider } from 'react-redux';
import store from './redux/store';
import PostStatusPopup from './components/postStatusPopup';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Config from './config';

function App() {

 
  return (
    <GoogleOAuthProvider clientId={Config.CLIENT_ID}>
      <Provider store={store}>
        {/* <AlertPopup/> */}
        <PostStatusPopup />
        <NavigationManager />
      </Provider>
    </GoogleOAuthProvider>
  );
}

export default App;
