import React from "react";
import Brand from "../../components/Brand";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";

const ConfirmationPage = () => {
    const navigate=useNavigate()

  return (
    <div className="d-flex justify-content-center align-items-center vh-100 bg-light">
      <div className="text-center">
        <i className="bi bi-check-circle-fill text-success display-4 mb-3"></i>
        <Brand />
        <h2 className="text-dark">Request Submitted!</h2>
        <p className="text-muted">
          Your request is under process. We will contact you once it's completed.
        </p>
        <CustomButton label={"Go Back to Home"} onClick={()=>navigate("/")} />
      </div>
    </div>
  );
};

export default ConfirmationPage;
