import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { PostCard } from '../../components/postCard';
import { customRequest, postApiService } from '../../functions/request';
import { setShowAlertPopup, setShowContestPayModal } from '../../redux/dataSlice';
import moment from "moment";
import CustomModal from '../../components/CustomModal';
import DayCard from '../../components/DayCard';
import { getLoggedUser } from '../../functions/helper';
import HorizontalContestTab from '../../components/horizontalContestTab';
import { MdArrowBack } from 'react-icons/md';
import OverlayLoader from '../../components/OverlayLoader';
import ChallengeDetails from './challengeDetails';
import ContestPayModal from '../../components/contestPayModal';
import { Colors } from '../../constants/colors';

let tabsData = [];

const box = {
  pending: {
    title: "Check Status to Unlock Your Access", color: Colors.yellow,
    message: "Complete the payment to proceed to the next step.",
    btnName: "Proceed to Pay"
  },
  1: {
    title: "You Have Unlocked the Access", color: Colors.green,
    message: "Success! You've unlocked the next step. Thank you. Keep going!",
    btnName: "Continue"

  },
  0: {
    title: "Pay to unlock your Access", color: Colors.yellow,
    message: `Payment is processing.Hang tight.If your payment was successful, you will get access to the paid features soon.
    Retry Payment if your payment failed earlier.
    OR Pay Now if you haven't paid earlier.`,
    btnName: "Proceed to Pay"

  },
  failed: {
    title: "Try again to Unlock Your Access", color: Colors.red,
    message: "Your earlier payment attempt failed. Please try again to proceed.",
    btnName: "Retry Payment"
  }
}

export default function ContestScreen() {
  let navigate = useNavigate();
  let location = useLocation();
  let [contest, setContest] = React.useState(null);
  const [posts, setPosts] = React.useState(null);
  const [winnerPosts, setWinnerPosts] = React.useState(null);
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = React.useState({
    id: "Challenge Details",
    name: `${location.state?.contestType} Details`
  },);
  const [contestDays, setContestDays] = React.useState([]);
  const [contestAlert, setContestAlert] = React.useState({ visible: false, message: '' });
  const [loading, setLoading] = React.useState(false);
  const [startDate, setStartDate] = React.useState(null);
  const [isParticipated, setisParticipated] = React.useState(false)
  const [participationId, setParticipationId] = React.useState(null)
  const [showCalender, setShowCalender] = React.useState(false)
  const [participation_date, setParticipation_date] = React.useState(new Date());
  const { showContestPayModal } = useSelector(state => state.data)
  const [isInterested, setIsInterested] = useState(null)
  const [paymentStatus, setPaymentStatus] = useState(null)
  const [retry, setRetry] = useState(false)


  const showInterest = async () => {
    try {
      setLoading(true)
      const body = { "participation_id": participationId }
      const resp = await postApiService("contest/contest-extend-interest", body)
      setLoading(false)
      dispatch(
        setShowAlertPopup(
          {
            show: true,
            title: "Alert",
            message: resp?.message,
          }
        )
      )
      return resp
    } catch (error) {
      setLoading(false)
      throw error
    }
  }
  const handlePay = async () => {
    try {
      if (paymentStatus === "1") {
        dispatch(setShowContestPayModal(false))
        return
      }
      dispatch(setShowContestPayModal(false))

      if (!isInterested) {
        await showInterest()
        setRetry(!retry)
      }
      if (!contest?.payment_link) {
        return dispatch(
          setShowAlertPopup(
            {
              show: true,
              title: "Alert",
              message: "Invalid payment link",
            }
          )
        )
      }
      if (contest?.payment_link) {
        window.open(contest?.payment_link, '_blank')?.focus()
      }
    } catch (error) {
      dispatch(
        setShowAlertPopup(
          {
            show: true,
            title: "Alert",
            message: error?.message,
          }
        )
      )
    }
  }


  const getContestPosts = () => {
    customRequest('post/contest-posts', { contest_id: location?.state?.contest_id }).then((res) => {
      if (res?.posts?.length) {
        setPosts(
          res?.posts?.map(post => {
            return (
              <PostCard key={post?.id} post={post} />
            )
          })
        )
      }else{
        setPosts(
          <div style={{ height: 260, width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: '0px' }}>
            <img src={require("../..//assets/empty_post.png")} style={{ height: 160, width: 240, objectFit: "contain" }} />
            <span>Nothing found.</span>
          </div>
        )
      } 

    }).catch((e) => { })
  }
  const getContest = () => {
    const user = getLoggedUser()
    customRequest('contest/singlecontest', {
      contest_id: location?.state?.contest_id,
      user_id: user?.id
    }).then((res) => {
      setParticipationId(res?.participation_id)
      setPaymentStatus(res?.payment_status)
      if (res.participate_date) {
        setisParticipated(true)
        setIsInterested(res?.in_interest)
        //user already participated
        setSelectedTab(tabsData[1])
        let temp = []
        //adding date for each day of contest task
        res.contestDays?.forEach((element, index) => {
          const item = {
            ...element,
            participate_date: index === 0 ? moment(res.participate_date)?.format('L') : moment(res.participate_date)?.add(index, 'days').format('L')

          }
          temp.push(item)

        });
        setContestDays(temp)
      } else {
        setContestDays(res.contestDays ? res.contestDays : [])
      }
      setContest(res.contest);
      setStartDate(res.participate_date)
      setWinnerPosts(res.winnerPosts);
    }).catch((e) => { })
    getContestPosts();
  }

  const acceptChallenge = () => {
    setLoading(true)
    customRequest('contest/participate-contest', { contest_id: contest.id, participation_date }).then(res => {
      setContestAlert({
        visible: true,
        message: contest?.is_paid_type === "paid" ? "FYI: This is a paid feature. Please complete the payment in the next step to unlock access" : res.message
      })
      setLoading(false)
    }).catch(e => {
      dispatch(
        setShowAlertPopup(
          {
            show: true,
            title: "Alert",
            message: "Something went wrong",
          }
        )
      )
    }
    ).finally(() => setLoading(false))
  }

  const handleParticipate = () => {
    if (getLoggedUser()) {
      if (moment().isAfter(moment(contest.end_date))) {
        dispatch(
          setShowAlertPopup(
            {
              show: true,
              title: "Alert",
              message: "Oops! this contest is closed.",
            }
          )
        )
      } else {


        if (contest.media_type === "external") {
          window.open(contest?.external_media_link, '_blank');
        } else {
          acceptChallenge()
          // navigate('/contestpost', { state: { source: "contest", contest_id: location.state.contest_id, media_type: contest.media_type } })
        }

      }
    } else {
      dispatch(
        setShowAlertPopup(
          {
            show: true,
            title: "Alert",
            message: "Please login to participate.",
            showLoginBtn: true
          }
        )
      )
    }
  }


  React.useEffect(() => {

    const tabs = [
      {
        id: "Challenge Details",
        name: `${location.state?.contestType} Details`
      },
      {
        id: "Challenge Schedule",
        name: `${location.state?.contestType} Schedule`
      },
      {
        id: "Entries",
        name: "Entries"
      },
      {
        id: "Leaderboard",
        name: "Leaderboard"
      }
    ]
    tabsData = tabs
    getContest();
  }, [retry])

  const handleSelectTab = (tab) => {
    setSelectedTab(tab)
  }

  if (contest == null) {
    return <div></div>
  }


  return (
    <React.Fragment>
      {loading && <OverlayLoader />}
      <div className="main_warp">
        <div style={{ height: 50, width: "100%", boxShadow: "0 4px 4px rgba(0,0,0,0.1)", marginBottom: 1, display: "flex", alignItems: "center", padding: "0 16px", position: "sticky", top: 0 }}>
          <MdArrowBack size={24} onClick={() => { navigate(-1) }} cursor="pointer" />
          <h4 style={{
            fontFamily: "Open Sans", fontSize: 20, marginLeft: 10, lineHeight: "1px", marginTop: 5,
          }}>
            {`#${contest?.hashtag}` || "Challenges"}
          </h4>
          <a className="search-icons searchicons" style={{ marginLeft: 'auto' }} onClick={() => { navigate(-1) }}>
            <img src="assets/images/Arrow-Left.svg" />
          </a>
        </div>

        <div class="aboutheader">
          <img src={contest.banner_image} class="img-fluid" />
        </div>
        <HorizontalContestTab hideScheduleTab={contestDays[0]?.participate_date ? false : true} tabsData={tabsData} selectedTab={selectedTab} handleSelectTab={handleSelectTab} />
        <div class="container px-0" style={{
          backgroundColor: '#fff', // White background
          flexGrow: 1, // Allow it to grow and take remaining space
          minHeight: '100vh', // Ensure it takes full height of the viewport
          width: '100%', // Ensure it stretches to full width
        }} >
          <div class="productdetail">
            <div class="productdetailview">
              <div class="tab-content" id="myTabContent">
                {selectedTab?.id === 'Challenge Details' &&
                  <ChallengeDetails
                    contest={contest} isParticipated={isParticipated}
                    handleParticipate={handleParticipate}
                    participateDate={participation_date}
                    setParticipation_date={setParticipation_date}
                    showCalender={showCalender}
                    setShowCalender={setShowCalender}
                  />}
                {selectedTab?.id === 'Challenge Schedule' &&

                  <div
                    style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'center' }}
                  >
                    {!!contestDays?.length && Array.isArray(contestDays) &&
                      contestDays?.map((elm, index) => {
                        return contest?.is_paid_type !== "free" && index + 1 === contest?.extend_after_days + 1 ?
                          <div className="flex-row day-card-extend" >
                            <button className='card day-card text-white'
                              style={{
                                backgroundColor: box[paymentStatus]?.color
                                  || (isInterested
                                    ? box["pending"]?.color
                                    : Colors.danger)
                              }}
                              onClick={() => dispatch(setShowContestPayModal(true))
                              }>
                              <h5 className='text-center text-white p-1'>
                                {
                                  box[paymentStatus]?.title
                                  || (isInterested
                                    ? box["pending"]?.title
                                    : "Pay to Unlock Your Access")
                                }
                              </h5>
                            </button>
                            <DayCard
                              contestMediaLink={contest?.external_media_link}
                              isPaidType={contest?.is_paid_type}
                              extendAfterDays={contest?.extend_after_days}
                              amount={contest?.amount}
                              key={index}
                              details={elm}
                              startDate={startDate}
                              banner={contest?.banner_image}
                              paymentStatus={paymentStatus}
                              contestId={contest?.id} hashTag={contest?.hashtag}
                              contestUnit={contest?.details[0]?.contest_unit_label} />
                          </div>
                          : <DayCard
                            numberOfDays={contestDays?.length}
                            contestMediaLink={contest?.external_media_link}
                            contestType={location.state?.contestType}
                            isPaidType={contest?.is_paid_type}
                            extendAfterDays={contest?.extend_after_days}
                            amount={contest?.amount}
                            key={index}
                            details={elm}
                            startDate={startDate}
                            banner={contest?.banner_image}
                            paymentStatus={paymentStatus}
                            contestId={contest?.id} hashTag={contest?.hashtag}
                            contestUnit={contest?.details[0]?.contest_unit_label} />
                      }
                      )}
                  </div>}
                {selectedTab?.id === 'Entries' && <div>
                  {
                    posts
                  }
                </div>}

                {selectedTab?.id === 'Leaderboard' && <div>
                  {
                    winnerPosts?.length !== 0
                      ?
                      winnerPosts?.map((post, index) => {
                        return (
                          <PostCard key={index} post={post} />
                        )
                      })
                      :
                      <div style={{ height: 260, width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: '0px' }}>
                        <img src={require("../..//assets/empty_post.png")} style={{ height: 160, width: 240, objectFit: "contain" }} />
                        <span>Details will get updated soon.</span>
                      </div>
                  }
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal windowmodal fade"
        id="Participate"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        //tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header px-3">
              <div
                class="modal-close ml-auto"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <img src="assets/images/Arrow-Left.svg" />
              </div>
            </div>
            <div class="modal-body">
              <div class="d-flex flex-column bd-highlight text-center">
                <div class="p-2 bd-highlight">
                  <div class="modallogoimg">
                    <img src="assets/images/Appicon.png" class="img-fluid" />
                  </div>
                </div>
                <div class="p-2 bd-highlight text-center">
                  <h2>Install the app..</h2>
                  <p class="text-center">Install the app today &amp; get access to Amet minim mollit non<br />deserunt ullamco est sit aliqua dolor do amet sint. </p>
                </div>
                <div class="pb-2 bd-highlight">
                  <div class="playstore"><a href="#"><img src="assets/images/Playstore.png" class="img-fluid" /></a></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {contestAlert?.visible && <CustomModal message={contestAlert?.message} hideModal={() => setContestAlert({ visible: false, message: '' })} />}
      {showContestPayModal &&
        <ContestPayModal
          handlePay={handlePay}
          amount={contest?.amount}
          isPaidType={contest?.is_paid_type}
          btnName={box[paymentStatus]?.btnName ?? "Proceed to Pay"}
          paymentStatus={paymentStatus}
          message={box[paymentStatus]?.message ?? "Complete the payment to proceed to the next step"}
          handleCancel={() => dispatch(setShowContestPayModal(false))} />}
    </React.Fragment>
  )
}