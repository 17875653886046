import { createSlice } from "@reduxjs/toolkit";

const dataSlice = createSlice({
    name: "dataSlice",
    initialState: {
        currentLocation: localStorage.getItem('location') === null ? "Everywhere" : localStorage.getItem('location'),
        allMoods: [],
        viewMoods: [],
        selectedMood: "All",
        selectedLanguageId: 2,//2 is default for english
        allLanguages: [],
        showPostStatusPopup: false,
        showContestPayModal: false,
        posts: [],
        postOffset: 0,
        alertPopup: {
            show: false,
            title: "Alert",
            message: "",
            showLoginBtn: false,
            registerBtn:false

        },
        redirectToContestId: null
    },
    reducers: {
        setCurrentLocation: (state, action) => {
            state.currentLocation = action.payload;
        },
        setAllMoods: (state, action) => {
            state.allMoods = action.payload;
        },
        setAddViewMoods: (state, action) => {
            if (!state.viewMoods.includes(action.payload)) {
                state.viewMoods.push(action.payload);
            }
        },
        setRemoveViewMoods: (state, action) => {
            if (state.viewMoods.includes(action.payload)) {
                state.viewMoods = state.viewMoods.filter(mood => mood != action.payload)
            }
        },
        setSelectedMood: (state, action) => {
            state.selectedMood = action.payload
        },
        setSelectedLanguageId: (state, action) => {
            state.selectedLanguageId = action.payload||2;
        },
        setShowHidePostStatusPopup: (state, action) => {
            state.showPostStatusPopup = action.payload;
        },
        setShowAlertPopup: (state, action) => {
            state.alertPopup = action.payload;
        },
        setAllLanguages: (state, action) => {
            state.allLanguages = action.payload;
        },
        setPostsInBulk: (state, action) => {
            state.posts = action.payload;
        },
        setAddInPosts: (state, action) => {
          if(action.payload?.length!==0){
                state.posts = [...state.posts, ...action.payload];
            }

            // action.payload.forEach(post => {
            //     state.posts.push(post)
            // })
            // state.posts.push(...action.payload);
        },
        setRedirectToContest: (state, action) => {
            state.redirectToContestId = action.payload;
        },
        setShowContestPayModal: (state, action) => {
            state.showContestPayModal = action.payload;
        },

    }
})

export const {
    setCurrentLocation,
    setAllMoods,
    setAddViewMoods,
    setRemoveViewMoods,
    setSelectedMood,
    setSelectedLanguageId,
    setShowHidePostStatusPopup,
    setShowAlertPopup,
    setAllLanguages,
    setPostsInBulk,
    setAddInPosts,
    setRedirectToContest,
    setShowContestPayModal
} = dataSlice.actions;

export default dataSlice.reducer;