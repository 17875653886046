import moment from 'moment'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setShowAlertPopup } from '../redux/dataSlice';
import { MdLockOutline } from 'react-icons/md';
import { Colors } from '../constants/colors'

function DayCard({ contestId, details, banner, hashTag, contestUnit, isPaidType, extendAfterDays, contestType, paymentStatus, contestMediaLink, numberOfDays }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  let isLock = false;

  if (paymentStatus !== "1") {
    isLock = isPaidType !== "free" && details?.name_of_day > extendAfterDays;
  }
  const onClick = () => {
    if (isLock) {
      // dispatch(setShowContestPayModal(true))
      return
    } else {
      if (isFutureDate(details?.participate_date)) {
        dispatch(
          setShowAlertPopup(
            {
              show: true,
              title: "Alert",
              message: "Can't see the content of upcoming days.",
            }
          )
        )
        return;
      }
      navigate('/challenge-content', { state: { banner: banner, contestId: contestId, day_id: details.id, hashTag: hashTag, contestUnit: contestUnit, contestType, contestMediaLink } })
    }

  }

  const isFutureDate = (dateString) => {
    const currentDate = moment();
    const inputDate = moment(dateString);

    return inputDate.isAfter(currentDate);
  };


  const cardBgColor = {
    done: '#21B05C',
    pending: '#C4A489',
    toBeDone: '#cfcec2',
    new: '#ffffff'
  }


  // console.log(isFutureDate(details?.participate_date))


  const styles = {
    card: {
      backgroundColor: details?.contest_days_user[0]?.status?.toLowerCase() === 'done' ? '#21B05C' : isFutureDate(details?.participate_date) ? '#D3D3D3' : '#ffffff',
    },

  }

  let cardClass = 'card'; // Default class

  if (window.innerWidth <= 768) {
    cardClass = 'card-small'; // Apply class for screens <= 768px
  } else if (window.innerWidth <= 480) {
    cardClass = 'card-tiny'; // Apply class for screens <= 480px
  }


  return (
    numberOfDays === 1 ?
      <div className="text-center my-4">
        <button
          onClick={onClick}
          className="btn btn-primary btn-lg px-4 py-2 fw-bold shadow-sm"
          style={{
            backgroundColor: Colors.primary,
            borderColor: Colors.primary,
            borderRadius: "8px",
            transition: "transform 0.2s, box-shadow 0.2s",
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = "scale(1.1)";
            e.currentTarget.style.boxShadow = "0 6px 12px rgba(0, 0, 0, 0.2)";
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = "scale(1)";
            e.currentTarget.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.1)";
          }}
        >
          <h4 className='text-white'>      
                LET'S START
          </h4>
        </button>
      </div> :
      <button className='card day-card' onClick={onClick} style={styles.card} >
        <h4 >{`Day ${details?.name_of_day}`}</h4>
        {isLock && <MdLockOutline size={20} color={Colors.grey} />}
      </button>

  )
}

export default DayCard 